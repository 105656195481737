<template>
  <div class="payment">
    <p class="title">请选择支付方式</p>
    <div class="payment-list">
      <div
        class="payment-item"
        v-for="item in payList"
        :key="item"
        @click="payment = item"
      >
        <a-radio :value="item" :checked="payment === item">
          <span>{{ item }}</span>
        </a-radio>
      </div>
    </div>

    <a-button type="primary" class="to-pay" @click="toPay">确认支付</a-button>

    <protocol
      v-if="showProtocol"
      @closeProtocol="showProtocol = false"
      @ok="handleOrder"
    />
  </div>
</template>

<script>
import Protocol from "./Protocol";
import { Radio, Button } from "ant-design-vue";
import { PaymentType, PaymentTypeEnum } from "../../../config/user";
export default {
  components: {
    Protocol,
    [Radio.name]: Radio,
    [Button.name]: Button,
  },
  data() {
    return {
      showProtocol: false,
      payment: PaymentType[PaymentTypeEnum.bank].short,
      payList: [
        PaymentType[PaymentTypeEnum.wxpay].short,
        PaymentType[PaymentTypeEnum.alipay].short,
        PaymentType[PaymentTypeEnum.bank].short,
      ],
    };
  },
  methods: {
    toPay() {
      this.showProtocol = true;
    },
    handleOrder() {
      this.showProtocol = false;
      this.$emit("order");
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  padding: 24px 200px 30px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    color: #39425c;
    font-size: 18px;
    margin-bottom: 21px;
  }

  .payment-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .payment-item {
      width: 240px;
      height: 55px;
      background-color: #e3eafa;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #647897;
        font-size: 14px;
      }
    }
  }

  .to-pay {
    width: 320px;
    height: 44px;
    margin-top: 37px;
    border-radius: 22px;
    font-size: 18px;
  }
}
</style>
