<template>
  <div class="protocol">
    <div class="protocol-panel">
      <div class="title">
        <span>e路播套餐购买协议</span>
        <span class="close-btn" @click="handleCancel">
          <i class="iconfont icon-icon_guanbi-xian"></i>
        </span>
      </div>

      <div class="protocol-panel-content">
        <div class="protocol-content">
          首部及导言
          <br />
          欢迎您使用宽维数藏软件及服务！
          <br />
          为使用宽维数藏软件（以下统称“本软件”）及服务（以下统称“本服务”），您应当阅读并遵守《宽维数藏软件许可及服务协议》（以下简称“本协议”），以及《宽维数藏软件隐私保护指引》。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款等，以及开通或使用某项服务的单独协议。限制、免责条款或者其他涉及您重大权益的条款可能以加粗、加下划线等形式提示您重点注意。如果您对本协议内容或页面提示信息有任何疑问，请先联系客服咨询。
        </div>
      </div>

      <div class="protocol-panel-footer">
        <a-button type="primary" @click="ok" :disabled="countDown > 0">
          <span v-if="countDown > 0">我知道了 ({{ countDown }}s)</span>
          <span v-else>我知道了</span>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "ant-design-vue";
export default {
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      timer: undefined,
      countDown: 10,
    };
  },
  mounted() {
    this.countDownTimer();
    let scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    document.body.style.cssText += "position:fixed;top:-" + scrollTop + "px;";
  },
  beforeDestroy() {
    let body = document.body;
    body.style.position = "static";
    let top = body.style.top;
    document.body.scrollTop = document.documentElement.scrollTop =
      -parseInt(top);
    body.style.top = "";
  },
  methods: {
    countDownTimer() {
      this.timer = setInterval(() => {
        this.countDown--;
        if (this.countDown <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    handleCancel() {
      this.$emit("closeProtocol");
    },
    ok() {
      console.log("我知道了");
      this.$emit("ok");
    },
  },
};
</script>

<style lang="scss" scoped>
.protocol {
  width: 100vw;
  height: 100vh;
  background-color: rgba(17, 18, 22, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .protocol-panel {
    width: 800px;
    height: 400px;
    background-color: #ffffff;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #f3f6ff;
      color: #333333;
      font-size: 18px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      .close-btn {
        width: 20px;
        height: 20px;
        margin-left: auto;
        border: 2px solid #999999;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
      }
      .icon-icon_guanbi-xian {
        font-size: 12px;
        color: #999999;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-left: 0.5px;
      }
    }

    .protocol-panel-content {
      height: 290px;
      padding: 24px;
      .protocol-content {
        width: 100%;
        height: 100%;
        border: 1px solid #eef4ff;
        border-radius: 10px;
        padding: 23px 37px 13px 27px;
        color: #39425c;
        font-size: 16px;
        line-height: 27px;
        overflow-y: auto;
      }
    }

    .protocol-panel-footer {
      width: 100%;
      height: 70px;
      padding-bottom: 26px;
      text-align: center;
      button {
        width: 200px;
        height: 44px;
        font-size: 18px;
        border: 0;
        outline: 0;
        &:disabled {
          background-color: rgba(54, 97, 235, 0.5);
          color: #ffffff;
        }
      }
    }
  }
}
</style>
