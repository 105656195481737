<template>
  <div class="purchase">
    <div class="purchase-title">
      <card-title
        title="购买套餐"
        description="请选择您需要的套餐"
      ></card-title>
    </div>

    <div class="type-switch">
      <span
        v-for="item in connectionTypes"
        :key="item.id"
        :class="{ active: item.id === selectType }"
        @click="selectType = item.id"
        >{{ item.name }}</span
      >
    </div>

    <div class="device-platform">
      <div class="platform">
        <title-underline title="支持平台" />
        <platform-list />
      </div>
      <div class="device">
        <title-underline title="支持设备" />
        <device-list />
      </div>
    </div>

    <area-package ref="areaPackage" :connection-type="selectType" />

    <payment @order="handleOrder" ref="payment" />
  </div>
</template>

<script>
import CardTitle from "@/components/CardTitle";
import TitleUnderline from "@/components/TitleUnderline";
import PlatformList from "./PlatformList";
import DeviceList from "./DeviceList";
import AreaPackage from "./AreaPackage";
import Payment from "./Payment";
import { PaymentTypeEnum, PaymentType } from "../../../config/user";
import { orderByBank, orderByAlipay } from "../../../request/package";
import { ConnectionTypeEnum } from "../../../config/system";

export default {
  components: {
    CardTitle,
    TitleUnderline,
    PlatformList,
    DeviceList,
    AreaPackage,
    Payment,
  },
  data() {
    return {
      selectType: ConnectionTypeEnum.Direct,
      connectionTypes: [
        {
          name: "专线套餐",
          id: ConnectionTypeEnum.Direct,
        },
        {
          name: "客户端套餐",
          id: ConnectionTypeEnum.Vpn,
        },
      ],
    };
  },
  created() {
    this.initSelectType();
  },
  methods: {
    initSelectType() {
      const { connectionType } = this.$route.query;
      if (connectionType) {
        this.selectType = parseInt(connectionType);
      }
    },
    handleOrder() {
      const params = {
        id: this.$refs.areaPackage.activePackageItem.id,
        months: this.$refs.areaPackage.months,
      };
      console.log(params);
      const paymentType = this.$refs.payment.payment;
      if (paymentType === PaymentType[PaymentTypeEnum.bank].short) {
        // 银行转账
        console.log("银行");
        this.orderByBank(params);
      } else if (paymentType === PaymentType[PaymentTypeEnum.alipay].short) {
        console.log("支付宝");
        this.orderByAlipay(params);
      } else {
        console.log("微信");
      }
    },
    orderByBank(params) {
      orderByBank(params).then((res = {}) => {
        const { id } = res;
        this.$router.push({
          path: "/pay/bank-transfer",
          query: {
            id,
          },
        });
      });
    },
    orderByAlipay(params) {
      orderByAlipay(params).then((res) => {
        document.write(res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";
@import "@/styles/scss/helper.scss";

.purchase {
  width: 1440px;
  margin: 0 auto;

  & > div {
    background-color: rgba(255, 255, 255, 0.6);
    margin-bottom: 10px;
  }

  .purchase-title {
    width: 100%;
    padding: 40px 40px 20px 40px;
    :deep(.title-container) {
      align-items: flex-end;
    }
  }

  .type-switch {
    background: transparent;
    height: 60px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    color: $primary-text-color;
    padding-bottom: 6px;
    @include flex(row, null, center);

    span {
      margin-left: 60px;
      cursor: pointer;

      &.active,
      &:hover {
        color: $primary-color;
      }
    }
  }

  .device-platform {
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0);
    .device,
    .platform {
      width: 715px;
      height: 410px;
      background-color: rgba(255, 255, 255, 0.6);
    }

    .platform {
      padding: 40px 88px 0 85px;
    }
    .device {
      padding: 40px 60px 0 60px;
    }
  }
}
</style>
