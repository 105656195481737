<template>
  <div class="area-package">
    <div class="area-package-content">
      <title-underline title="可用地域" />
      <div class="area-list">
        <div
          class="area-item"
          v-for="(item, index) in list"
          :key="item.route"
          :class="{ active: activeAreaIndex === index }"
          @click="activeAreaIndex = index"
        >
          <img :src="item.icon" alt="" />
          <span>{{ item.route }}</span>
        </div>
      </div>

      <title-underline title="可选套餐" />
      <div class="package-list">
        <div
          class="package-item"
          v-for="(item, index) in packageList"
          :key="item.id"
          :class="{ active: activePackageIndex === index }"
          @click="activePackageIndex = index"
        >
          <div class="price-hour">
            <span>低至</span>
            <span>￥{{ item.price | priceHour }}</span>
            <span>/小时</span>
          </div>
          {{ item.name }}
        </div>
      </div>

      <title-underline title="使用时长" />
      <div class="duration">
        <div class="moon" @click="duration = 'moon'">
          <a-radio value="moon" :checked="duration === 'moon'">
            <span>按月购买</span>
            <a-input-number v-model="moon" :min="1" />
            <span>月</span>
          </a-radio>
        </div>
        <div class="year" @click="duration = 'year'">
          <a-radio value="year" :checked="duration === 'year'">
            <span>按年购买</span>
            <a-input-number v-model="year" :min="1" />
            <span>年</span>
          </a-radio>
        </div>
      </div>
    </div>
    <div class="total-cost">
      <span class="total">共计</span>
      <span class="price">
        <span>￥</span>
        <span>{{ total }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import TitleUnderline from "@/components/TitleUnderline";
import { Radio, InputNumber } from "ant-design-vue";
// 下面是国旗图片
import America from "@/assets/images/purchase/America.png";
import Thailand from "@/assets/images/purchase/Thailand.png";
import Malaysia from "@/assets/images/purchase/Malaysia.png";
import Japan from "@/assets/images/purchase/Japan.png";
import SouthKorea from "@/assets/images/purchase/SouthKorea.png";
import Singapore from "@/assets/images/purchase/Singapore.png";
import Vietnam from "@/assets/images/purchase/Vietnam.jpg";
import China from "@/assets/images/purchase/China.png";
import Indonesia from "@/assets/images/purchase/Indonesia.png";
import Britain from "@/assets/images/purchase/Britain.png";
// 上面是国旗图片
import { formatCentToYuan } from "@/utils/format";
import { packageList } from "@/request/package";
import { ConnectionTypeEnum } from "../../../config/system";

export default {
  components: {
    TitleUnderline,
    [Radio.name]: Radio,
    [InputNumber.name]: InputNumber,
  },
  data() {
    return {
      list: [],
      areaList: [
        { id: 1, icon: America, name: "美国" },
        { id: 2, icon: Thailand, name: "泰国" },
        { id: 3, icon: Malaysia, name: "马来西亚" },
        { id: 4, icon: Japan, name: "日本" },
        { id: 5, icon: Britain, name: "英国" },
        { id: 6, icon: China, name: "中国台湾" },
        { id: 7, icon: Vietnam, name: "越南" },
        { id: 8, icon: Indonesia, name: "印尼" },
        { id: 9, icon: SouthKorea, name: "韩国" },
        { id: 10, icon: Singapore, name: "新加坡" },
      ],
      activeAreaIndex: 0,
      // packageList: [
      //   { id: 1, name: "基础版" },
      //   { id: 2, name: "进阶版" },
      //   { id: 3, name: "领先版" },
      //   { id: 4, name: "尊享版" },
      //   { id: 5, name: "旗舰版" },
      // ],
      activePackageIndex: 0,
      duration: "moon",
      moon: 1,
      year: 1,
    };
  },
  props: {
    connectionType: {
      type: Number,
      default: ConnectionTypeEnum.Direct,
    },
  },
  watch: {
    activeAreaIndex() {
      this.activePackageIndex = 0;
    },
    connectionType() {
      this.activePackageIndex = 0;
      this.getPackageList();
    },
  },
  computed: {
    packageList() {
      const areaPackage = this.list[this.activeAreaIndex];
      return areaPackage ? areaPackage.products || [] : [];
    },
    months() {
      return this.duration === "moon" ? 1 * this.moon : 12 * this.year;
    },
    activePackageItem() {
      return this.packageList[this.activePackageIndex] || {};
    },
    total() {
      const { price = 0 } = this.activePackageItem;
      return formatCentToYuan(price * this.months);
    },
  },
  filters: {
    priceHour(price) {
      return formatCentToYuan(price / 30 / 24);
    },
  },
  created() {
    this.getPackageList();
  },
  methods: {
    onChange() {
      console.log("onChange");
    },
    getPackageList() {
      packageList(this.connectionType).then((res) => {
        for (const item of res) {
          if (item.route.includes("北美")) {
            item.icon = America;
          } else if (item.route.includes("香港")) {
            item.icon = China;
          } else if (item.route.includes("台湾")) {
            item.icon = China;
          } else if (item.route.includes("南美")) {
            item.icon = Indonesia;
          } else if (item.route.includes("英国")) {
            item.icon = Britain;
          } else if (item.route.includes("越南")) {
            item.icon = Vietnam;
          } else if (item.route.includes("新加坡")) {
            item.icon = Singapore;
          } else if (item.route.includes("日本")) {
            item.icon = Japan;
          } else if (item.route.includes("马来西亚")) {
            item.icon = Malaysia;
          } else if (item.route.includes("泰国")) {
            item.icon = Thailand;
          } else if (item.route.includes("韩国")) {
            item.icon = SouthKorea;
          } else if (item.route.includes("美国")) {
            item.icon = America;
          } else if (item.route.includes("印尼")) {
            item.icon = Indonesia;
          }
        }
        this.list = res || [];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.area-package {
  display: flex;
  flex-direction: column;

  .area-package-content {
    padding: 30px 108px 30px 80px;
    :deep(.title-underline) {
      display: inline-block;
      margin-bottom: 24px;
    }
    .area-list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 39px;
      .area-item {
        width: 179px;
        height: 60px;
        background-color: #ffffff;
        border: 1px solid #e9f0ff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -1px;
        cursor: pointer;
        &:nth-child(n + 8) {
          margin-top: 10px;
        }
        &:nth-child(1),
        &:nth-child(7n + 1) {
          margin-left: 0;
        }
        img {
          width: 44px;
          height: 30px;
          object-fit: contain;
          margin-right: 8px;
        }
        span {
          color: #39425c;
          font-size: 18px;
        }
        &.active {
          background-color: #ecf2ff;
          border: 1px solid #3661eb;
          position: relative;
          font-weight: bold;
          z-index: 2;
          span {
            color: #3661eb;
          }
        }
      }
    }

    .package-list {
      display: flex;
      margin-bottom: 39px;
      .package-item {
        width: 250px;
        height: 60px;
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border: 1px solid #e9f0ff;
        margin-right: 1px;
        font-size: 18px;
        position: relative;
        .price-hour {
          position: absolute;
          width: 250px;
          height: 60px;
          background-color: #fc9415;
          top: -60px;
          padding-bottom: 16px;
          justify-content: center;
          align-items: flex-end;
          display: none;
          span {
            color: #ffffff;
            font-weight: bold;
            &:nth-child(1),
            &:nth-child(3) {
              font-size: 18px;
            }
            &:nth-child(2) {
              font-size: 32px;
              margin-bottom: -3px;
            }
          }
        }
        &.active {
          color: #ffffff;
          font-weight: Bold;
          background-color: #3661eb;
          border: 0;
          .price-hour {
            display: flex;
          }
        }
      }
    }

    .duration {
      width: 100%;
      height: 120px;
      display: flex;
      justify-content: space-between;
      .moon,
      .year {
        width: 622px;
        height: 100%;
        background-color: #e3eafa;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.ant-input-number) {
          margin: 0 10px;
        }
        span {
          color: #39425c;
          font-size: 18px;
        }
      }
    }
  }

  .total-cost {
    width: 100%;
    height: 90px;
    background-color: #ffffff;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    align-items: center;
    .total {
      color: #39425c;
      font-size: 18px;
      margin-right: 21px;
    }
    .price {
      display: flex;
      align-items: flex-end;
      & > span {
        color: #f47755;
        font-weight: bold;
        &:first-child {
          font-size: 24px;
        }
        &:last-child {
          font-size: 36px;
        }
      }
    }
  }
}
</style>
